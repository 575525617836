import { types } from 'mobx-state-tree';

const PostManagementUtilities = types
  .model('PostManagementUtilities', {
    showAddOrUpdatePostModal: types.optional(types.boolean, false),
    isUpdate: types.optional(types.boolean, false),
    updateId: types.optional(types.integer, 0),
    search: types.optional(types.string, ''),
    props: types.optional(types.array(types.string), ['name_of_publication', 'product_name', 'highlights']),

  })
  .views((self) => ({}))
  .actions((self) => ({

    setToggleShowAddOrUpdatePostModal(isUpdate = false) {
      self.isUpdate = isUpdate ? true : false;
      self.showAddOrUpdatePostModal = !self.showAddOrUpdatePostModal;
    },

    setUpdateId(id) {
      console.log(id)
      self.updateId = id;
    },

    setSearch(searchValue) {
      self.search = searchValue;
    },

  }));

export default PostManagementUtilities;
