import { message } from 'antd';
import CountryData from '@core_data/countries/Countries.json';

const PostManagementController = ({ store, form, t }) => {

  const getPosts = async () => {
    const page = 1;
    const search = store.PostManagementUtilities.search;
    const props = store.PostManagementUtilities.props;
    let params = { page, search, props, size:10};

    await store.posts.LIST(params)
  };
  const getPostsTotal = async () => {
    await store.posts.LIST()
  };
  const getMonthTotal = async ( monthDate ) => {
    await store.posts.GET_MONTH( monthDate )
  };
  
  
  const handleToggleAddOrUpdateShowPostModal = (isUpdate = false, postData = null) => {
    if (isUpdate) {
      form.setFieldsValue(postData);
      store.PostManagementUtilities.setUpdateId(postData?.id);
      // store.PostManagementUtilities.setCities(postData?.country);
    }
    store.PostManagementUtilities.setToggleShowAddOrUpdatePostModal(isUpdate);
  };


  const handleUpdateOrCreatePost = async (values, isUpdate, isSelf = false) => {
    message.loading({
      content: isUpdate ? t('Updating Post') : t('Creating Post'),
      key: 'creatingPost'
    });

    const params = isUpdate
      ? [ store.PostManagementUtilities.updateId, values] // If updating self use id in login store
      : [values];

    let [successMessage, error] = await store.posts[isUpdate ? 'UPDATE' : 'CREATE'](...params);

    let success = await _showresultMessage(error, {
      successMessage: t(successMessage.message) // isUpdate ? t('User Updated') :
    });

    if (success) {
      if (!isSelf) {
        form.resetFields();
        store.PostManagementUtilities.setToggleShowAddOrUpdatePostModal();
      }
    }
  };


  const handlePostSearch = async (search) => {
    search = search.trim();
    const props = store.PostManagementUtilities.props;
    let params = { search, props };

    store.PostManagementUtilities.setSearch(search);
    await store.posts.LIST(params);
  };

  const onChangePostPage = async (page) => {
    const search = store.PostManagementUtilities.search;
    const props = store.PostManagementUtilities.props;

    let params = { page, search, props, size:10 };

    await store.posts.LIST(params);
  };


  async function _showresultMessage(error, { successMessage }) {
    return new Promise((resolve, reject) => {
      if (error) {
        message.error({ content: t(error.response.data.error.message), key: 'creatingPost' });

        reject(false);
      } else {
        message.success({ content: successMessage, key: 'creatingPost' });
        resolve(true);
      }
    });
  }
  
  
  return {
    getPosts,
    getPostsTotal,
    handleToggleAddOrUpdateShowPostModal,
    handleUpdateOrCreatePost,
    handlePostSearch,
    onChangePostPage,
    getMonthTotal
  };
};

export default PostManagementController;
