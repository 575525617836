import { types } from "mobx-state-tree";
import { 
  PostManagementUtilities, 
  PostState 
} from "@app_modules/Post/model";

const AppStore = {

  posts: types.optional(PostState, {}),
  PostManagementUtilities: types.optional(PostManagementUtilities, {}),
};

export default AppStore;
