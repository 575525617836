import React from 'react';
import { GlobalOutlined } from '@ant-design/icons';
import Routes from '@app_routing/Routes';

const AppMenus = [
  {
    key: '3',
    icon: <GlobalOutlined />,
    route: Routes.POST_ROUTE,
    menuName: 'Post'
  }
];

export default AppMenus;
