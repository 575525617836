import { lazy } from 'react';

const PostManagementHeader = lazy(() => import('./PostManagementHeader'));
const AddOrUpdatePostModal = lazy(() => import('./AddOrUpdatePostModal'));
const PostTable = lazy(() => import('./PostTable'));

export {
  PostManagementHeader,
  AddOrUpdatePostModal,
  PostTable
};