import React from 'react'
import { useTranslation } from 'react-i18next';


/**CSS IMPORTS*/
import '@app_modules/Dashboard/component/css/style.css'

const DashBBox = ( { dashLength, dashTitle, children } ) => {
  const { t } = useTranslation('common');
  return (
    <div className="dashB-box">
      <p className="dashB-box-length">
        {dashLength}
      </p>
      {
        children !== undefined?
          <p className="dashB-box-text">
            {children}
          </p>
        :
          <p className="dashB-box-text">{t(dashTitle)}</p>
      }
    </div>
  )
}

export default DashBBox
