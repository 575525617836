import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react';
import { Col, Row, Card } from 'antd';
// import { FileExcelOutlined, DownloadOutlined } from '@ant-design/icons';





/**CONTROLLER IMPORTS*/
import { PostManagementController } from '@app_modules/Post/controller';
import DashBBox from './DashBBox';
import MonthSelect from './DashBBox/MonthSelect';
import NppmChart from './Charts/NppmChart';





const PostDetails = ({ store }) => {

  const { getPostsTotal, getMonthTotal } = PostManagementController({
    store
  });

  useEffect(getPostsTotal, []);
  useEffect(()=>{
    getMonthTotal('2021-08')
  }, []);




  return (
    <>
      <Card className="shadow-sm">
        <Row gutter={[16, 16]} className="d-flex align-items-center">
            <Col span={3} className="pr-2">
              <DashBBox 
                dashLength={store.posts.state.length} 
                dashTitle={'Total Post'} 
              />
            </Col>
            <Col span={3} className="pr-2 ">
              <DashBBox 
                dashLength={store.posts.postPerMonth.length} 
              >
                <Row>
                  <MonthSelect getMonthTotal={getMonthTotal} />
                </Row>
              </DashBBox>
            </Col>
        </Row>
      </Card>
      <Card className="shadow-sm mt-5">
        <Row>
          <Col span={24} className="pr-2">
            <NppmChart />
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default inject('store')(observer(PostDetails));
