import React, { Suspense } from 'react';
import { Form } from 'antd';


/**CORE IMPORTS */
import { FallBackLoaders } from '@core_common/components';
import { PostManagementHeader, PostTable } from '@app_modules/Post/components';

function Post() {
  const [form] = Form.useForm();


  return (
      <>
      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <PostManagementHeader  form={form}/>
      </Suspense>

      <Suspense fallback={FallBackLoaders.EmptyLoader}>
          <PostTable form={form}/>
      </Suspense>
    </>

  );
}

export default Post;
