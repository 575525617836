import { types } from 'mobx-state-tree';

const PostModel = types
  .model('PostModel', {
    id:                   types.optional(types.integer, 0),
    name_of_publication:  types.optional(types.string, ''),
    product_name:         types.optional(types.string, ''),
    highlights:           types.optional(types.string, ''),
    key_learning:         types.optional(types.string, ''),
    media_outlet:         types.optional(types.string, ''),
    circulation:          types.optional(types.string, ''),
    frequency:            types.optional(types.string, ''),
    product_category:     types.optional(types.string, ''),
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default PostModel;
