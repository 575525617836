import React from 'react'
import { DatePicker, Row, Col } from 'antd';


const MonthSelect = ({ getMonthTotal }) => {


  return (
    <>
      <Row gutter={[16, 16]} className="d-flex align-items-center">
        <Col span={24} className="pr-2 ">
          <DatePicker picker="month" placeholder="Post on" showToday={true}
            onChange={(e) => { 
              getMonthTotal(e.format('YYYY-MM'))
            }}
          />
        </Col>
      </Row>
    </>
  )
}

export default MonthSelect
