import { cast, flow, types } from 'mobx-state-tree';
import PostModel from './PostModel';
import ApplyCrud from '@core_state_management/Helpers/RootCrud';

import axios from 'axios'

const API_PATH = '/api/post';

export default types
  .model('PostState', {
    state: types.optional(types.array(PostModel), []),
    singleState: types.optional(PostModel, {}),
    total: types.optional(types.number, 0),
    loading: types.optional(types.boolean, false),
    isCreating: types.optional(types.boolean, false),
    postPerMonth: types.optional(types.array(PostModel), []),
    
  })
  .actions((self) => ({
    ...ApplyCrud(API_PATH, self),
    GET_MONTH: flow(function* (values) {
      try {

        const { data } = yield axios.post(`${API_PATH}/total_per_month`, { monthPost:values });
        console.log(data)
        self.postPerMonth = cast(data)

        // return [{ message: 'Created!', data }, null];

      } catch (error) {
        self.isCreating = false;
        return [null, error];
      }
    })

  }))
  .views((self) => ({}));
